@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');

.overlay {
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-family: 'Noto Sans', sans-serif;
}
.replySection {
  border-left: 1px solid rgb(235, 235, 235);
  margin-left: 25px;
  padding: 0px 0px 0px 15px;
}
.comment-title {
  font-family: 'Noto Sans', sans-serif;
  font-size: 30px;
  font-weight: 700;
  color: #202020d1;
}
.no-comDiv {
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-weight: 700;
  color: #202020d1;
  margin-top: 40px;
}