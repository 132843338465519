.signBox {
    border: 1px solid rgb(221, 221, 221);
    border-radius: 8px;
    background-color: transparent;
    padding: 15px;
    display: flex;
    justify-content: space-between;
  
    .signLine {
      margin-top: 5px;
      font-weight: 700;
      color: rgb(156, 156, 156);
      font-size: 17px;
    }
  
    .loginBtn {
      border: 2px solid #6381a8;;
      border-radius: 8px;
      background-color: transparent;
      padding: 5px 10px;
      color: #6381a8;
      font-weight: bolder;
      margin-right: 10px;
      font-size: 16px;
      cursor: pointer;
      &:hover {
        border: 2px solid rgb(0, 183, 238);
        color: rgb(0, 183, 238);
      }
    }
    .signBtn {
      border: 2px solid #5dbea3;
      border-radius: 8px;
      background-color: #5dbea3;
      padding: 5px 10px;
      color: rgb(255, 255, 255);
      font-weight: bolder;
      font-size: 16px;
      cursor: pointer;
      &:hover {
        background-color: rgb(0, 183, 238);
        border: 2px solid rgb(0, 183, 238);
      }
    }
  }