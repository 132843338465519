.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.house-image-thumbnail {
  max-width: 15vh;
  max-height: 30vh;
}

.house-image-detail {
  max-width: 15vh;
  max-height: 30vh;
}
.individual-comments {
  text-align: left;
}
.main-wrapper {
  padding: 0 0 100px;
  min-height: 100vh;
  position: relative;
}

.content-wrap { 
  padding-bottom: 2.5rem;    /* Footer height */
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;   
  background-color: #f5f5f5;
}

.search-result-card {
  margin-bottom: 100px;
}

.search-bar {
 margin-bottom: 50px;
 margin-top: 10px;
}
.search-bar-container {
  margin-top: 50px;
  margin-bottom: 50px;
  width: available;
}

.children-centered {
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.centered-child { 
  border-radius: 25px;
  border: 2px solid #73AD21;
  width: 80vh;
  height: 40vh;
  padding: 2%;
}

.share-button-row {
  float: right;
  width: fit-content;
  margin: 10px;
  text-align: right;
}

.share-button-col {
  float: right;
  width: fit-content;
}

.savePropertyBtn {
  background-color: transparent;
  border: none;
  text-align: center;
  float: right;
  max-width: min-content;
}

.image-wrapper {
  position: relative;
  display: inline-block;
}
.image-wrapper .img {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.image-wrapper .actions-div {
  position: absolute;
  width: fit-content;
  top: 4vh;
  right: 0vh;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #D3D3D3;
  font-size: 16px;
  padding: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: #000;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
}

.dropdown-toggle::after {
  display: none !important; 
}

.userInfoButton {
  position: relative;
}
.userNotifications {
  z-index: 2;
  position: absolute;
  top: 0;
  right: 0;
}
