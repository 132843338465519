.commentBlock {
  display: flex;
}
.form {
    display: flex;
    background-color: rgb(243, 243, 243);
    padding: 20px;
    border-radius: 8px;
    .userImg {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0px 10px;
    }
  
    .postComment {
      width: 100%;
      border: none;
      border-bottom: 1px solid rgb(24, 24, 24);
      text-decoration: none;
      background-color: transparent;
      margin-left: 6px;
    }
    .postComment:focus {
      outline: none;
      border-bottom: 2px solid #4681f4;
    }
    .postComment::placeholder {
      margin-top: -2px;
    }
  
    .postBtn {
      border: 2px solid #4681f4;;
      border-radius: 8px;
      background-color: #4681f4;;
      padding: 5px 10px;
      color: white;
      font-weight: bolder;
      margin-left: 15px;
      font-size: 16px;
      cursor: pointer;
      padding: 5px 20px;
      &:hover {
        border: 2px solid rgb(0, 184, 240);
        background-color: rgb(0, 184, 240);
      }
    }
    .cancelBtn {
      border: 2px solid rgb(237, 237, 237);
      border-radius: 8px;
      background-color: rgb(237, 237, 237);
      padding: 5px 10px;
      color: rgb(174, 174, 174);
      font-weight: bolder;
      margin-left: 15px;
      font-size: 16px;
      cursor: pointer;
      padding: 5px 20px;
      &:hover {
        border: 2px solid rgb(210, 210, 210);
        background-color: rgb(210, 210, 210);
      }
    }
  }
  
  .imgdefault {
    width: 38px;
    height: 38px;
    border-radius: 19px;
  }
  .hr-style {
    width: 100%;
    border-top: 1px solid;
  }
  .emoji-input {
    display: flex;
    width: 100%;
    position: relative;
    .emoji-icon {
      //background-image: url('../../assets/smile.svg');
      position: relative;
      width: 24px;
      background-repeat: no-repeat;
      top: 14px;
      cursor: pointer;
    }
  }
  .emoji-picker-react {
    z-index: 1000;
    position: absolute !important;
    right: -63px;
    top: 50px;
  }
  
  .rdw-editor-wrapper {
    width: 100%;
  }
  .advanced-form {
    padding: 0px;
    flex-direction: column;
  }
  .rdw-editor-main {
    max-height: 200px;
    overflow: scroll;
  }
  .advanced-btns {
    width: 100%;
    display: flex;
    margin: 6px 0px 0px 0px;
  }
  .advanced-border {
    border: 1px solid #e8e8e8;
    padding: 10px;
    border-radius: 10px;
    .advanced-border:focus-within {
      border: 1px solid #353535;
    }
  }
  .advanced-post {
    margin-left: unset !important;
  }
  .advanced-cancel {
    margin-right: 15px;
    margin-left: unset !important;
  }
  .advanced-overlay {
    display: flex;
    margin: 10px 0px;
    width: 100%;
  }
  .advanced-input {
    margin-left: 6px;
    width: 100%;
  }